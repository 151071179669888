import React from "react";
import { Row, Col } from "antd";

const Heading = ({ title, description, theme }) => {
  const headerClassName = `section-header ${
    theme === "white" ? "text-color-white" : ""
  }`;
  const paraClassName = `section-para ${
    theme === "white" ? "text-color-off-white" : ""
  }`;
  return (
    <Row type="flex" justify="center">
      <Col xs={22} md={18} lg={20} type="flex" align="middle">
        <h2 className={headerClassName}>{title}</h2>
        <p className={paraClassName}>{description}</p>
      </Col>
    </Row>
  );
};
export default Heading;
