import React from "react";
import { Card, Row, Col } from "antd";
import Heading from "./Heading";
const { Meta } = Card;

const Countries = ({ data, layout, component_id }) => {
  const { values } = data;
  const { headerColor } = layout;
  return (
    <React.Fragment>
      {data.heading && (
        <Heading
          title={data.heading.title}
          description={data.heading.description}
          theme={headerColor}
          component_id={component_id}
        />
      )}
      <Row type="flex" justify="center" gutter={[25, 25]}>
        {values.map(country => {
          return (
            <Col key={`${country.name}`} xs={12} md={6} lg={6}>
              <Card
                className="card"
                cover={
                  <img
                    className="countries-img"
                    alt={`${country.image_path}-flag`}
                    src={require(`../images/flags/${country.image_path}.png`)}
                  />
                }
              >
                <Meta className="countries-text" title={country.name} />
              </Card>
            </Col>
          );
        })}
      </Row>
    </React.Fragment>
  );
};

export default Countries;
