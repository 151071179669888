import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Tag,
  notification
} from "antd";
const { Option } = Select;
const { CheckableTag } = Tag;

const regisData = {
  components: {
    mobile: {
      name: "registrationMobileComponent"
    },
    desktop: {
      name: "registration"
    }
  },
  data: {
    values: {
      title: "STUDY ABROAD",
      image_path_1: "registration_header_image",
      image_path_2: "glob_header_image",
      tag_choice: "multiple",
      tags: [
        "Course Selection",
        "Scholarship",
        "Resume analysis",
        "Visa guidance"
      ],
      countries: [
        "Australia",
        "New Zealand",
        "Canada",
        "Germany",
        "USA",
        "Finland",
        "Ireland",
        "Malta",
        "Croatia",
        "France",
        "Italy",
        "Slovenia",
        "Spain",
        "Singapore"
      ],
      intakes: ["2020", "2021"],
      tnc: "/terms-and-conditions"
    }
  },
  layout: {
    mobile: {},
    desktop: {},
    headerColor: "",
    backgroundColor: "bg-color-blue"
  }
};

const RegistrationForm = props => {
  const [selectedTags, setSelectedTags] = useState(["Course Selection"]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    tag_choice,
    image_path_1,
    image_path_2,
    tags,
    countries,
    intakes
  } = regisData.data.values;

  const handleSubmit = async e => {
    e.preventDefault();
    const values = await props.form.validateFieldsAndScroll();
    try {
      setIsLoading(true);
      const {
        name,
        email,
        phone,
        preferred_intake,
        interested_countries,
        has_agreed_to_terms
      } = values;
      const response = await axios.post(
        "https://www.entrancezone.com/api/v1/abroad",
        {
          name,
          email,
          phone,
          preferred_intake,
          interested_countries,
          interested_services: selectedTags,
          has_agreed_to_terms
        }
      );
      notification.success({
        message: response.data.message
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "Something went wrong"
      });
    }
  };

  const handleChange = (tag, checked) => {
    let nextSelectedTags;
    if (tag_choice === "single") {
      checked && (nextSelectedTags = [tag]);
    } else if (tag_choice === "multiple") {
      nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter(t => t !== tag);
    }
    setSelectedTags(nextSelectedTags);
  };

  const { getFieldDecorator } = props.form;

  return (
    <Row
      className="register-text"
      type="flex"
      style={{ clipPath: "polygon(0 0,100% 0,100% 90%,76% 100%,0% 82%)" }}
    >
      <Col
        xs={24}
        md={12}
        lg={12}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <img
          src={require(`../images/${image_path_1}.svg`)}
          alt=""
          className=""
          style={{ width: "85%", marginTop: "1rem" }}
        />
        <img
          style={{ width: "45%", alignSelf: "center", marginTop: "18rem" }}
          alt="registeration-page"
          src={require(`../images/${image_path_2}.svg`)}
          className="register-text__glob-header-img"
        />
      </Col>
      <Col xs={24} md={12}>
        <div className="register-form-column">
          <div className="register-form">
            <p>Get me free</p>
            <div style={{ marginLeft: "1rem" }}>
              {tags.map(tag => (
                <CheckableTag
                  key={tag}
                  checked={selectedTags.indexOf(tag) > -1}
                  onChange={checked => handleChange(tag, checked)}
                  className="registration-form-tag"
                >
                  {tag}
                </CheckableTag>
              ))}
            </div>
            <Form onSubmit={handleSubmit} style={{ textAlign: "initial" }}>
              <div className="registration-ant-form">
                <Form.Item label="Name">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your name!",
                        whitespace: true
                      }
                    ]
                  })(<Input className="register-input" />)}
                </Form.Item>

                <Form.Item label="E-mail">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "The input is not valid E-mail!"
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!"
                      }
                    ]
                  })(<Input className="register-input" />)}
                </Form.Item>

                <Form.Item label="Phone Number">
                  {getFieldDecorator("phone", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your phone number!"
                      }
                    ]
                  })(
                    <Input
                      className="register-input"
                      style={{ width: "100%" }}
                    />
                  )}
                </Form.Item>
                <Row type="flex" justify="center" gutter={[20, 20]}>
                  <Col xs={12} md={12}>
                    <Form.Item label="Interested country">
                      {getFieldDecorator("interested_countries", {
                        rules: [
                          { required: true, message: "please select a country" }
                        ]
                      })(
                        <Select
                          placeholder="Please select a country"
                          className="register-select"
                          showArrow={true}
                          mode="multiple"
                        >
                          {countries.map(country => (
                            <Option key={country} value={country}>
                              {country}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={12}>
                    <Form.Item label="Prefered Intake" hasFeedback>
                      {getFieldDecorator("preferred_intake", {
                        rules: [
                          {
                            required: true,
                            message: "please select an Intake "
                          }
                        ]
                      })(
                        <Select
                          placeholder="Please select an Intake"
                          className="register-select"
                        >
                          {intakes.map(intake => (
                            <Option key={intake} value={intake}>
                              {intake}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  {getFieldDecorator("has_agreed_to_terms", {
                    valuePropName: "checked",
                    rules: [
                      {
                        required: true,
                        message: "Please accept the terms and conditions"
                      }
                    ]
                  })(
                    <Checkbox className="register-checkbox">
                      I accept all the
                      <Link to="/terms-and-condition">
                        {" "}
                        terms and conditions
                      </Link>
                    </Checkbox>
                  )}
                </Form.Item>
              </div>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                block
              >
                SUBMIT
              </Button>
            </Form>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const WrappedRegistrationForm = Form.create({ name: "register" })(
  RegistrationForm
);

export default WrappedRegistrationForm;
