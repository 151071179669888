import React, { useState, useEffect } from "react";
import { Carousel, Card, Row, Col, Divider } from "antd";
import Heading from "./Heading";
import { enquireScreen } from "enquire-js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = ({ data, layout }) => {
  const [isMobile, setIsMobile] = useState(null);

  const setting = {
    dots: true,
    infinite: true,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    focusOnSelect: true,
    pauseOnHover: true,
    swipeToSlide: true
  };

  useEffect(() => {
    enquireScreen(status => setIsMobile(status));
    return () => {
      //cleanup
    };
  }, [isMobile]);

  const { values } = data;
  const { headerColor } = layout;
  return (
    <React.Fragment>
      <Divider className="testimonial-divider" />
      {data.heading && (
        <Heading
          title={data.heading.title}
          description={data.heading.description}
          theme={headerColor}
        />
      )}
      <Carousel className="testimonial" {...setting}>
        {values.map(testimonial => {
          return (
            <Card key={testimonial.image_path}>
              <Row
                type="flex"
                justify="center"
                className="testimonial-carousel-row">
                <Col xs={24} md={6} type="flex" align="middle">
                  <img
                    alt={testimonial.name}
                    src={testimonial.image_path}
                    style={{ width: "10rem", height: "10rem" }}
                  />
                  <h3
                    style={{
                      fontSize: "18px",
                      marginBottom: ".4rem",
                      marginTop: "2rem"
                    }}>
                    {testimonial.name}
                  </h3>
                  <h4
                    style={{
                      color: "#666"
                    }}>
                    {testimonial.college_name}
                  </h4>
                </Col>
                <Col xs={24} md={2}>
                  <Divider type={isMobile ? "horizontal" : "vertical"} />
                </Col>
                <Col xs={24} md={16}>
                  <p style={{ textAlign: "left", fontSize: "1.6rem" }}>
                    {testimonial.comment}
                  </p>
                </Col>
              </Row>
            </Card>
          );
        })}
      </Carousel>
    </React.Fragment>
  );
};

export default Testimonials;
