import React from "react";
import "./styles/App.css";
import "antd/dist/antd.css";
import ReactGA from "react-ga";
import HomePageComponent from "./pages/HomePageComponent";
import TermsAndCondition from "./pages/TermsAndCondition";
import FooterLayout from "./components/FooterLayout";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

ReactGA.initialize("UA-83305228-9", {
  debug: process.env.NODE_ENV !== "production"
});
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route
            exact
            path="/terms-and-condition"
            component={TermsAndCondition}
          />
          <Route component={HomePageComponent} />
        </Switch>
        <FooterLayout />
      </Router>
    </div>
  );
};

export default App;
