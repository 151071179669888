import React from "react";
import { Row, Col } from "antd";

const TermsAndCondition = () => {
  return (
    <Row
      className="terms-and-conditions"
      style={{ backgroundColor: "ghostwhite" }}>
      <Col xs={24} md={3}></Col>
      <Col xs={24} md={18} style={{ backgroundColor: "white" }}>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center"
            }}>
            <div
              style={{
                width: "7%",
                height: "2rem",
                backgroundColor: "#176299",
                marginRight: "2rem"
              }}></div>
            <h2 className="terms-condition-heading">Privacy Policy</h2>
          </div>
          <p className="heading-para">
            SchoolApply believes that your privacy is important. Therefore we
            value the trust you have placed in us when helping you finding the
            right school. We are committed to protecting and safeguarding any
            personal data you give us. This Privacy Policy describes how
            Schoolapply use and process your personal data and how we use
            cookies. It also tells you how you can contact us if you have
            questions about your personal data or about cookies.
            <br />
            <br />
            SchoolApply offers education-related services through its own
            websites and mobile apps and through other online platforms such as
            partners’ websites and social media. The information that follows
            applies to all of these platforms. SchoolApply may amend the Privacy
            and Cookies Policy from time to time, so visit this page regularly
            to keep abreast of the updates. If you disagree with this Privacy
            and Cookies Policy, you should discontinue using our services.
          </p>

          <div className="paragraph-cell">
            <h4 className="section-heading">Privacy</h4>
            <h5 className="question-heading">
              What kind of personal information does SchoolApply use?
            </h5>
            <p className="para">
              In order to use the services of SchoolApply you need to create an
              account. When you do this you are asked for your name and email
              address. You might also be asked for your home address, and
              telephone, number. When you apply to school(s), you will be asked
              for additional information such as: payment information,
              guardian's information, academic history, employment history,
              admission test information, etc.
              <br />
              <br />
              <br />
              When you visit our websites, even if you do not create an account,
              we may collect certain information, such as your IP address, which
              browser you’re using, and information about your computer’s
              operating system, application version, language settings and pages
              that have been shown to you. If you are using a mobile device, we
              might also collect data that identifies your mobile device,
              device-specific settings and characteristics and
              latitude/longitude details. When you make an application, our
              system registers through which means and from which websites you
              have made your application.
              <br />
              <br />
              We may also receive information about you when you use certain
              social media services
            </p>
            <h5 className="question-heading">
              Why does SchoolApply collect, use and share your personal data?
            </h5>
            <ul className="list-style">
              <li>
                Schools: First and foremost, we use your personal data to
                complete and administer your online application and forward your
                details to the school you have applied for.
              </li>
              <li>
                Customer service: We provide international customer service.
                Sharing your details with our global customer service staff
                allows us to respond when you need us – including helping you to
                find appropriate programs and responding to any questions you
                might have about your applications.
              </li>
              <li>
                Account administration: We offer a user account facility on our
                website. We use the information you give us to administer this,
                allowing you to manage your information, applications, and
                manage your personal settings. Managing personal settings allows
                you to keep and share lists, see schools that you have searched
                for before, and see other information you have provided about
                schools and programs.
              </li>
              <li>
                Marketing activities: We also use your information for marketing
                activities, as permitted by law. For example:
                <ul className="nested-list">
                  <li>
                    When you make a search with us or set up a user account, we
                    or the schools on the SchoolApply platform may use your
                    contact information to send you news and information
                    regarding similar school related products and services. With
                    your consent – if consent is required under local law – we
                    may also send you other regular newsletters by email. You
                    can opt out, or unsubscribe, from marketing communications
                    at any time using the "Unsubscribe" link in each newsletter,
                    or you can administer your subscriptions through your
                    account (if you've created one).
                  </li>
                  <li>
                    Based on the information you share with us, individualized
                    offers may be shown to you on the SchoolApply website, in
                    mobile apps or on third-party websites, including social
                    media sites. These might be offers that you can book
                    directly on the SchoolApply website, or third-party offers
                    or products we think you might find interesting.
                  </li>
                  <li>
                    If we believe that a particular offer may be of interest to
                    you, we may decide to make contact with you by phone.
                  </li>
                </ul>
              </li>
              <li>
                Other communications: There may be other times when we get in
                touch by email, by post, by phone or by texting you, depending
                on the contact information you share with us. There could be a
                number of reasons for this:
                <ul className="nested-list">
                  <li>
                    We may need to respond to and handle requests you have made.
                  </li>
                  <li>
                    If you have not finalized an application online, we may call
                    or email you a reminder to continue with your application.
                  </li>
                  <li>
                    When you use our services, we may send you a questionnaire
                    or invite you to provide a review about your experience with
                    SchoolApply.
                  </li>
                  <li>
                    We may also send you other material related to your
                    application, such as how to contact SchoolApply if you need
                    assistance while you are away, and information that we feel
                    might be useful to you in planning your departure or getting
                    the best out of your study time.
                  </li>
                </ul>
              </li>
              <li>
                Market research: We sometimes ask our customers to take part in
                market research. Any additional personal details that you give
                us as part of the market research will be used only with your
                consent.
              </li>
              <li>
                Fraud detection and prevention: We may use personal data for the
                detection and prevention of fraud and other illegal or unwanted
                activities.
              </li>
              <li>
                Improving our services: Finally, we use personal data for
                analytical purposes, to improve our services, to enhance the
                user experience, and to improve the functionality and quality of
                our services.
              </li>
            </ul>
            <h5 className="question-heading">
              How does SchoolApply use social media?
            </h5>
            <p className="para">
              We use social media to promote schools and to promote, improve and
              facilitate our own services. For example, we have integrated
              social media plugins into the SchoolApply website. So when you
              click on one of the buttons and register with your social media
              account, information is shared with your social media provider,
              and possibly presented on your social media profile to be shared
              with others in your network.
              <br />
              <br />
              In addition to implementing these buttons, SchoolApply uses social
              media by maintaining accounts and offering apps on several social
              media sites. These social media services may allow you to share
              information with SchoolApply. When you register with a social
              media app, you will be told which information will be shared with
              SchoolApply. The information you choose to share with us may
              include the basic information that’s available in your social
              media profile, email address, status updates and your list of
              friends. This information is necessary to create a unique user
              experience either in the app itself or on our websites. It
              facilitates such things as personalizing our website to suit your
              needs, connecting you with your friends and analysing and
              enhancing our services.
              <br />
              <br />
              We may also enable you to sign in to SchoolApply services with
              your social media accounts. Your social media provider will be
              able to tell you more about how they use and process your data in
              such cases.
            </p>

            <h5 className="question-heading">
              How does SchoolApply share your data with third parties?
            </h5>
            <p className="para">
              In certain circumstances, we may share your personal data with
              third parties.
            </p>
            <ul className="list-style">
              <li>
                The school(s) you applied to: In order to process your
                application, we need to transfer relevant details to the school
                you have applied to. We want you to make an informed decision
                about your choice of education. Thus SchoolApply may share your
                information with schools that you might have an interest in.
                This information may also be used by SchoolApply or schools to
                give you personalised offers.
              </li>
              <li>
                Third-party service providers: We may use service providers
                (such as "data processors") to process your personal data
                strictly on our behalf. This processing would be for purposes
                such as facilitating payments, sending out marketing material or
                for analytical support services. These processors are bound by
                confidentiality clauses and are not allowed to use your personal
                data for their own purposes or any other purpose.
              </li>
              <li>
                Competent authorities: We disclose personal data to law
                enforcement and other governmental authorities insofar as it is
                required by law or is strictly necessary for the prevention,
                detection or prosecution of criminal acts and fraud.
              </li>
              <li>
                Business partners: We work with business partners around the
                world to distribute or advertise schools and to help our
                business partners distribute and advertise their
                education-related services. This may mean that their services
                are integrated into our website or they have been enabled to
                show a customised advertisement on our website – or we are
                advertising on theirs. When you create an account on one of our
                business partners’ websites, certain personal data that you give
                them may be forwarded to us. Certain business partners may
                receive your personal data from us if requested by you. When you
                create an account on a business partner's’ website, please read
                the privacy policies on these business partners’ websites for
                more information.
              </li>
            </ul>
            <h5 className="question-heading">
              How can you control the personal data you have given to
              SchoolApply?
            </h5>
            <p className="para">
              SchoolApply share your information with Schools and business
              partners in their respective countries. We base this sharing of
              information on your consent and not on any adequacy decision by
              the European Commission or any similar organisation. Your consent
              to this sharing is important to us and you have the right to
              withdraw your consent at any time.
              <br />
              <br />
              You have the right to ask restriction of processing concerning
              your data or to object to processing as well as the right to data
              portability. If you have any concerns about how SchoolApply
              process data please contact us at LINK. Otherwise you can contact
              the Swiss the Federal Data Protection Information Commissioner
              (FDPIC).
            </p>

            <h5 className="question-heading">
              Can you have access to the data?
            </h5>
            <p className="para">
              You always have the right to review the personal information we
              keep about you. You can request an overview of your personal data
              by emailing us at support@schoolapply.com Please write 'Request
              personal information' in the subject line of your email and
              include a copy of your identity card to help us prevent
              unauthorized individuals from accessing your personal data.
              <br />
              <br />f the personal information we have for you is incorrect, we
              will update it at your request. You can also ask us to remove your
              personal data from our customer database by sending an email to
              support@schoolapply.com with 'Request for removal of personal
              information' in the subject line. However, we may need to retain
              certain information, for example for legal or administrative
              purposes, such as record keeping or to detect fraudulent
              activities. You can delete your user account at any time by
              signing into your account on the SchoolApply website and choosing
              to remove your account.
            </p>
            <h5 className="question-heading">For how long is data stored?</h5>
            <p className="para">
              We store data for the purpose of helping you with choices
              regarding your education. Thus SchoolApply will store the data
              until the end of your education or until we can presume that you
              are no longer making decisions regarding your potential education.
              We deem this to be approximately five years after your
              registration at SchoolApply. If you want your personal data to be
              deleted in advance please contact us.
            </p>
            <h5 className="question-heading">Retention of Records</h5>
            <p className="para">
              For each electronic storage media, you should identify (perhaps in
              a supplementary schedule) the operating system, application and
              hardware requirements necessary to be able to run the data item,
              in the next paragraph you can identify how you will ensure that,
              as you upgrade software and hardware in the future, you retain the
              capability of accessing the stored data. If you use an independent
              storage vault solution, this is where you would pull it to your
              procedures.
            </p>
            <h5 className="question-heading">
              Who is responsible for the processing of personal data on the
              SchoolApply website and apps?
            </h5>
            <p className="para">
              SchoolApply AG controls the processing of personal data on its
              websites and mobile apps. SchoolApply AG. is a private limited
              liability company, incorporated under the laws of the Switzerland
              and has its offices at Bahnhofstrasse 7, 6300, Zug, Switzerland
              and registered with the trade register of Zug under registration
              number CHE-435.851.615
              <br />
              <br />
              If you have any suggestions or comments about this privacy notice,
              please send an email to support@schoolapply.com
            </p>
            <h5 className="question-heading">
              How does SchoolApply make use of mobile devices?
            </h5>
            <p className="para">
              We have free apps for a variety of mobile devices and use versions
              of our regular website that have been optimised for mobile. These
              apps and mobile websites process the personal details you give us
              in much the same way as our website does. With your consent, we
              may send you push notifications with information about educational
              services.
            </p>
            <h4 className="section-heading">Cookies</h4>
            <p className="para">
              SchoolApply uses cookies, below you can read about what they are
              and how we use them.
            </p>
            <h5 className="question-heading">What is a cookie?</h5>
            <p className="para">
              A cookie is a small amount of data that is placed in the browser
              of your computer or on your mobile device. This Privacy and
              Cookies Policy applies to cookies and similar technologies
              (hereafter together referred to as “cookies”).
            </p>

            <h5 className="question-heading">Why are cookies used?</h5>
            <p className="para">
              Web pages have no memory. If you are surfing from page to page
              within a website, you will not be recognized as the same user
              across pages. Cookies allow you to be recognized as the same user
              across the pages of a website. Cookies also allow your choices to
              be remembered – choices such as the language you prefer, the
              currency you use and your search criteria. They will also make
              sure you are recognized when you return to a website.
            </p>
            <h5 className="question-heading">
              Do all cookies do the same thing?
            </h5>
            <p className="para">
              No, there are different types of cookie and different ways of
              using them. Cookies can be categorized according to their
              function, their lifespan and according to who places them on a
              website.
            </p>
            <h5 className="question-heading">How are cookies used?</h5>
            <p className="para">
              SchoolApply’s website uses the following types of cookie:
            </p>
            <ul className="list-style">
              <li>
                Technical cookies: We try to give our visitors an advanced and
                user-friendly website that adapts automatically to their needs
                and wishes. To achieve this, we use technical cookies to show
                you our website, to make it function correctly, to create your
                user account, to sign you in and to manage your application.
                These technical cookies are absolutely necessary for our website
                to function properly.
              </li>
              <li>
                Functional cookies: We also use functional cookies to remember
                your preferences and to help you to use our website efficiently
                and effectively, for example by remembering your preferred
                currency and language, your searches and the schools you viewed
                earlier. These functional cookies are not strictly necessary for
                the functioning of our website, but they add functionality for
                you and enhance your experience.
              </li>
              <li>
                Analytics cookies: We use these cookies to gain insight into how
                our visitors use the website, to find out what works and what
                doesn't, to optimize and improve our website and to ensure we
                continue to be interesting and relevant. The data we gather
                includes which web pages you have viewed, which referring/exit
                pages you have entered and left from, which platform type you
                have used, date and time stamp information and details such as
                the number of clicks you make on a given page, your mouse
                movements and scrolling activity, the search words you use and
                the text you type while using our website. We also make use of
                analytics cookies as part of our online advertising campaigns to
                learn how users interact with our website after they have been
                shown an online advertisement – which may include advertisements
                on third-party websites. However, we will not know who you are,
                and will only obtain anonymous data. Our business partners may
                also use analytics cookies to learn if their customers make use
                of accommodation offers integrated into their websites.
              </li>
              <li>
                Commercial cookies: We use third-party cookies as well as our
                own to display personalized advertisements on our websites and
                on other websites. This is called “retargeting,” and it is based
                on browsing activities, such as the schools you have been
                searching for, the schools you have viewed and the content you
                have been shown. Commercial cookies may also be used by selected
                third parties to display their products and services through our
                website.
              </li>
            </ul>
            <h5 className="question-heading">
              How long do SchoolApply cookies stay active?
            </h5>
            <p className="para">
              The cookies we use have varying lifespans. The maximum lifespan we
              set on some of them is five years from your last visit to our
              website. You can erase all cookies from your browser any time you
              want to.
            </p>
            <h5 className="question-heading">
              How can you recognize SchoolApply cookies?
            </h5>
            <p className="para">
              You can find our cookies in your browser settings.
            </p>
            <h5 className="question-heading">
              Does SchoolApply use third-party marketing and analytics cookies?
            </h5>
            <p className="para">
              Yes, SchoolApply uses the services of trusted and recognized
              online advertising and marketing companies. SchoolApply may also
              use third-party providers for analytical purposes. To enable their
              services, these companies need to place cookies.
              <br />
              <br />
              The providers we use are committed to building consumer awareness
              and establishing responsible business and data management
              practices and standards.
              <br />
              <br />
              When it comes to online advertising and marketing companies, we
              strive to only work with companies that are members of the Network
              Advertising Initiative (NAI) and/or the Interactive Advertising
              Bureau (IAB). Members of NAI and IAB adhere to industry standards
              and codes of conduct. NAI and IAB members allow you to opt out of
              the behavioral advertising. Visit www.networkadvertising.org and
              www.youronlinechoices.com to identify the NAI members that may
              have placed an advertising cookie file on your computer. To opt
              out of an NAI or IAB member's behavioral advertising program,
              simply check the box that corresponds to the company from which
              you wish to opt out.
              <br />
              <br />
              In order to control the collection of data for analytical purposes
              by Google Analytics, you may want to visit the following link:
              Google Analytics Opt-out Browser Add-on.
            </p>
            <h5 className="question-heading">
              Who has access to SchoolApply cookie data?
            </h5>
            <p className="para">
              Only SchoolApply has access to SchoolApply cookies. Cookies placed
              by third parties can be accessed by these third parties.
            </p>
            <h5 className="question-heading">
              How can you manage your cookie preferences?
            </h5>
            <p className="para">
              Using your browser settings in, for example, Internet Explorer,
              Safari, Firefox or Chrome, you can set which cookies to accept and
              which to reject. Where you find these settings depends on which
              browser you use. Use the "Help" function in your browser to locate
              the settings you need.
              <br />
              <br />
              If you choose not to accept certain cookies, you may not be able
              to use some functions on our website. However, we do not otherwise
              support “Do Not Track” browser settings. Opting out of an online
              advertising network does not mean that you will no longer receive
              or be subject to online advertising or marketing analysis. It
              means that the network from which you opted out will no longer
              deliver ads tailored to your web preferences and browsing
              patterns.
            </p>
            <h5 className="question-heading">
              Does SchoolApply use web beacons?
            </h5>
            <p className="para">
              As well as using cookies, SchoolApply sometimes uses web beacons.
              A web beacon is a tiny graphic image of just one pixel that’s
              delivered to your computer either as part of a web page request or
              in an HTML email message. Either directly or through service
              providers, we use these pixels as part of our online
              advertisements either on our website or on third-party websites to
              learn whether a user who is being shown an online advertisement
              also creates an account; to track conversion with partner websites
              and to analyze the traffic patterns of users to optimize the
              services we bring to you.
            </p>
            <h4 className="section-heading">Security</h4>
            <h5 className="question-heading">
              What security procedures does SchoolApply put in place to
              safeguard your personal data?
            </h5>
            <p className="para">
              In accordance with European data protection laws, we observe
              reasonable procedures to prevent unauthorized access and the
              misuse of personal data.
              <br />
              <br />
              We use appropriate business systems and procedures to protect and
              safeguard the personal data you give us. We also use security
              procedures and technical and physical restrictions for accessing
              and using the personal data on our servers. Only authorized
              personnel are permitted to access personal data in the course of
              their work.
              <br />
              <br />
              Your credit card details will remain hashed in our system for
              fraud detection purposes.
            </p>
            <h5 className="question-heading">
              Personal Data Breach Notification Procedures
            </h5>
            <p className="para">
              This procedure applies in the event of a personal data breach
              under Article 33 of the GDPR - Notification of a personal data
              breach to the supervisory authority - and Article 34 -
              Communication of a personal data breach to the data subject
              <br />
              <br />
              All users and owners of SchoolApply are required to be aware of,
              and to follow this procedure in the event of a personal data
              breach. All staff are responsible for reporting any personal data
              breach to the Data Protection Officer / Head of IT (CIO)
            </p>
            <h5 className="question-heading">
              Procedure - Breach notification data processor to data controller
            </h5>
            <p className="para">
              SchoolApply reports any personal data breach or security incident
              to the data controller without undue delay. These contact details
              are recorded in the Internal Breach Register (GDPR REC 4.5).
              SchoolApply provides the controller with all of the details of the
              breach.
              <br />
              <br />
              The breach notification is made by email and phone call. A
              confirmation of receipt is made by email and phone call.
            </p>
            <h5 className="question-heading">
              Procedure - Breach notification data processor to data subject
            </h5>
            <p className="para">
              If the personal data breach is likely to result in high risk to
              the rights and freedoms of the data subject, SchoolApply notifies
              those/the data subjects affected immediately.
              <br />
              <br />
              The notification of the data subject describes the breach in clear
              and plain language and in accordance with the procedures above.
            </p>
            <h5 className="question-heading">Children</h5>
            <p className="para">
              The services offered by SchoolApply are not directed at children
              under 16 years old. The use of any of our services is only allowed
              with the valid consent of a parent or a guardian. If we receive
              information from a child under 16 years old, we reserve the right
              to delete it.
            </p>
            <h5 className="question-heading">Know Your Rights</h5>
            <p className="para">
              These terms and conditions are to be read in conjunction with the
              The General Data Protection Regulation (GDPR) (Regulation (EU)
              2016/679) and, in the event there is any discrepancy, the latter
              prevails.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "6rem"
            }}>
            <div
              style={{
                width: "7%",
                height: "2rem",
                backgroundColor: "#176299",
                marginRight: "2rem"
              }}></div>
            <h2 className="terms-condition-heading">Terms of Service</h2>
          </div>
          <p className="heading-para">
            SchoolApply invite you to access of our services, but please note
            that your use is subject to your review and agreement with these
            Terms of Service. This document describes in detail your rights and
            our rights relating to the provision of the Service so please review
            these Terms carefully.
          </p>

          <div
            className="paragraph-cell"
            style={{
              marginBottom: "6rem"
            }}>
            <p className="para">
              These terms and conditions, as may be amended from time to time,
              apply to all our services directly or indirectly (through
              partners) made available online, through any mobile device, by
              email or by telephone. By accessing, browsing and using our
              website or any of our applications through whatever platform
              (hereafter collectively referred to as the "website") and/or by
              completing a school application, you acknowledge and agree to have
              read, understood and agreed to the terms and conditions set out
              below (including the Privacy Policy).
              <br />
              <br />
              These pages, the content and infrastructure of these pages, and
              the online application service provided on these pages and through
              the website (the "service") are owned, operated and provided by
              Schoolapply AG ("SchoolApply", “SA” "us", "we" or "our") and are
              provided for your personal, non-commercial use only, subject to
              the terms and conditions set out below.
            </p>
            <h5 className="question-heading">Scope of our Service</h5>
            <p className="para">
              Through the website SchoolApply and its partners provide an online
              platform through which schools, will be listed, and through which
              visitors to the website can apply. By making an application
              through SchoolApply, you don’t enter into a direct (legally
              binding) contractual relationship with the school at which you
              apply. From the point at which you make your application, we act
              solely as an intermediary between you and the school, transmitting
              the details of your application to the relevant school.
              SchoolApply is not necessarily a representative, in connection
              with or in affiliation with the Schools that can be displayed on
              the SchoolApply platform.
              <br />
              <br />
              When rendering our services, the information that we disclose is
              based on the information provided to us by school websites.
              Although we will use reasonable skill and care in performing our
              services we will not verify if, and cannot guarantee that, all
              information is accurate, complete or correct, nor can we be held
              responsible for any errors, any interruptions (whether due to any
              (temporary and/or partial) breakdown, repair, upgrade or
              maintenance of our website or otherwise), inaccurate, misleading
              or untrue information or non-delivery of information. Our website
              does not constitute and should not be regarded as a recommendation
              or endorsement of the quality, service level, qualification or
              (star) rating of any school made available.
              <br />
              <br />
              Our services are made available for personal and non-commercial
              use only. Therefore, you are not allowed to re-sell, deep-link,
              use, copy, monitor (e.g. spider, scrape), display, download or
              reproduce any content or information, software, products or
              services available on our website for any commercial or
              competitive activity or purpose.
            </p>
            <h5 className="question-heading">Prices</h5>
            <p className="para">
              All prices on the SchoolApply website are calculated per person
              per year, unless stated differently on our website or email.
              <br />
              <br />
              Sometimes lower prices are available for a specific program at a
              school, however, these prices made by schools may carry special
              restrictions and conditions.
              <br />
              <br />
              The currency converter is for information purposes only and should
              not be relied upon as accurate and real time; actual rates may
              vary.
              <br />
              <br />
              Obvious errors and mistakes (including misprints) are not binding.
            </p>
            <h5 className="question-heading">Privacy and cookies</h5>
            <p className="para">
              SchoolApply respects your privacy. Please have a look at our
              privacy and cookies policy for further information.
            </p>
            <h5 className="question-heading">SchoolApply fees</h5>
            <p className="para">
              Our service is free except for the administration fee. The
              administration fee is a non-refundable fee (subject to our
              acceptance guarantee) that is charged when submitting
              applications.
              <br />
              <br />
              Schools pay a commission (being a small percentage of the tuition
              price) to SchoolApply when the student has confirmed enrollment
              with the school. Schools with premium listings pay SchoolApply for
              enabling such profiles.
              <br />
              <br />
              Schools may also pay SchoolApply for other marketing opportunities
              such as newsletters and sponsored content.
            </p>
            <h5 className="question-heading">Acceptance Guarantee</h5>
            <p className="para">
              Our Acceptance Guarantee is an offer to students who meet the
              minimum requirements, as assessed by SchoolApply. In order to
              qualify, you must be a registered and fully paid student that
              meets the minimum requirements of the higher educational
              institution chosen. If you do not meet these requirements, you
              will not be automatically eligible for the Acceptance Guarantee.
              <br />
              <br />
              In the event that you qualify for the Acceptance Guarantee and you
              are not accepted to a higher education institution, we will refund
              you the one-time administration fee you have paid to us in respect
              of our services. This is limited to only our administration fee
              and does not cover any other expenses, including any expenses
              required to make or prepare applications.
            </p>
            <h5 className="question-heading">Disclaimer</h5>
            <p className="para">
              To the extent permitted by law, neither we nor any of our
              officers, directors, employees, representatives, subsidiaries,
              partner companies, licensees, agents or others involved in
              creating, sponsoring, promoting, or otherwise making available the
              site and its contents shall be liable for (i) any punitive,
              special, indirect or consequential loss or damages, any loss of
              production, loss of profit, loss of revenue, loss of contract,
              loss of or damage to goodwill or reputation, loss of claim, (ii)
              any inaccuracy relating to the (descriptive) information
              (including prices, availability and ratings) of the school as made
              available on our website, (iii) the services rendered or the
              products offered by the school or other business partners, (iv)
              any (direct, indirect, consequential or punitive) damages, losses
              or costs suffered, incurred or paid by you, pursuant to, arising
              out of or in connection with the use, inability to use or delay of
              our website, or (v) any (personal) injury, death, property damage,
              or other (direct, indirect, special, consequential or punitive)
              damages, losses or costs suffered, incurred or paid by you,
              whether due to (legal) acts, errors, breaches, (gross) negligence,
              willful misconduct, omissions, non-performance,
              misrepresentations, tort or strict liability by or (wholly or
              partly) attributable to the school or any of our other business
              partners (including any of their employees, directors, officers,
              agents, representatives or partners companies) whose products or
              service are (directly or indirectly) made available, offered or
              promoted on or through the website, including any (partial)
              cancellation, strike, force majeure or any other event beyond our
              control.
              <br />
              <br />
              Whether the school you enroll with charges you (or has charged
              you, or we are facilitating the payment of the school fees, you
              agree and acknowledge that the school is at all times responsible
              for the collection, withholding, remittance and payment of the
              applicable taxes due on the total amount of the school fees to the
              relevant tax authorities. SchoolApply is not liable or responsible
              for the remittance, collection, withholding or payment of the
              relevant taxes due on the school fees to the relevant tax
              authorities.
            </p>
            <h5 className="question-heading">License</h5>
            <p className="para">
              By uploading content such as text or photos/images onto our system
              (for instance in addition to a review or personal account) you
              certify, warrant and agree that you own the copyright to the
              content and that you agree that SchoolApply may use the uploaded
              content on its (mobile) website and app, and in (online/offline)
              promotional materials and publications and as SchoolApply at its
              discretion sees fit. You are granting SchoolApply a non-exclusive,
              worldwide, unconditional, perpetual right and license to use,
              reproduce, display, have reproduced, distribute, sublicense,
              communicate and make available the content as SchoolApply at its
              discretion sees fit. By uploading this content the person
              uploading the content accepts full legal and moral responsibility
              of any and all legal claims that are made by any third parties
              (including, but not limited to, school owners) due to SchoolApply
              publishing and using this content. SchoolApply does not own or
              endorse the content that are uploaded. The truthfulness, validity
              and right to use of all content is assumed by the person who
              uploaded the content, and is not the responsibility of
              SchoolApply. SchoolApply disclaims all responsibility and
              liability for the content posted. The person who uploaded the
              content warrants that the content shall not contain any viruses,
              Trojan horses or infected files and shall not contain any
              pornographic, illegal, obscene, insulting, objectionable or
              inappropriate material and does not infringe any third party
              (intellectual property right, copyright or privacy) rights. Any
              content that does not meet the aforesaid criteria will not be
              posted and/or can be removed/deleted by SchoolApply at any time
              and without prior notice.
            </p>
            <h5 className="question-heading">Intellectual property rights</h5>
            <p className="para">
              Unless stated otherwise, the software required for our services or
              available at or used by our website and the intellectual property
              rights (including the copyrights) of the contents and information
              of and material on our website are owned by Schoolapply, its
              suppliers or providers.
              <br />
              <br />
              SchoolApply exclusively retains ownership of all rights, title and
              interest in and to (all intellectual property rights of) (the look
              and feel (including infrastructure) of) the website on which the
              service is made available (including the reviews and translated
              content) and you are not entitled to copy, scrape,
              (hyper-/deep)link to, publish, promote, market, integrate,
              utilize, combine or otherwise use the content (including any
              translations thereof and the reviews) or our brand without our
              express written permission. To the extent that you would (wholly
              or partly) use or combine our (translated) content (including
              reviews) or would otherwise own any intellectual property rights
              in the website or any (translated) content or reviews, you hereby
              assign, transfer and set over all such intellectual property
              rights to SchoolApply. Any unlawful use or any of the
              aforementioned actions or behaviour will constitute a material
              infringement of our intellectual property rights (including
              copyright and database right)
            </p>
            <h5 className="question-heading">Miscellaneous</h5>
            <p className="para">
              To the extent permitted by law, these terms and conditions and the
              provision of our services shall be governed by and construed in
              accordance with Swiss law and any dispute arising out of these
              general terms and conditions and our services shall exclusively be
              submitted to the competent courts in Zug, Switzerland.
              <br />
              <br />
              The original English version of these terms and conditions may
              have been translated into other languages. The translated version
              is a courtesy and office translation only and you cannot derive
              any rights from the translated version. In the event of a dispute
              about the contents or interpretation of these terms and conditions
              or inconsistency or discrepancy between the English version and
              any other language version of these terms and conditions, the
              English language version to the extent permitted by law shall
              apply, prevail and be conclusive. The English version is available
              on our website (by selecting the English language) or shall be
              sent to you upon your written request.
              <br />
              <br />
              If any provision of these terms and conditions is or becomes
              invalid, unenforceable or non-binding, you shall remain bound by
              all other provisions hereof. In such event, such invalid provision
              shall nonetheless be enforced to the fullest extent permitted by
              applicable law, and you will at least agree to accept a similar
              effect as the invalid, unenforceable or non-binding provision,
              given the contents and purpose of these terms and conditions.
            </p>
            <h5 className="question-heading">
              About SchoolApply and the support companies
            </h5>
            <p className="para">
              The online school application service is rendered by Schoolapply
              AG., which is a private limited liability company, incorporated
              under the laws of Switzerland and having its offices at
              Bahnhofstrasse 7, 6300, Zug, Switzerland and registered with the
              trade register of Zug under registration number CHE-435.851.615
            </p>
          </div>
        </div>
      </Col>
      <Col xs={24} md={3}></Col>
    </Row>
  );
};

export default TermsAndCondition;
