import React, { useState } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Tag,
  notification
} from "antd";
const { Option } = Select;
const { CheckableTag } = Tag;

const RegistrationForm = props => {
  const [selectedTags, setSelectedTags] = useState(["Course Selection"]);
  const [isLoading, setIsLoading] = useState(false);

  const { data } = props;
  const { values } = data;
  const {
    tag_choice,
    header_image_path,

    tags,
    countries,
    intakes,
    tnc
  } = values;

  // const handleSubmit = e => {
  //   e.preventDefault();
  //   props.form.validateFieldsAndScroll((err, formData) => {
  //     if (!err) {
  //
  //       setFormData({ selectedTags, ...formData });
  //     }
  //   });

  //
  // };

  const handleSubmit = async e => {
    e.preventDefault();
    const values = await props.form.validateFieldsAndScroll();
    try {
      setIsLoading(true);
      const {
        name,
        email,
        phone,
        preferred_intake,
        interested_countries,
        has_agreed_to_terms
      } = values;
      const response = await axios.post(
        "https://www.entrancezone.com/api/v1/abroad",
        {
          name,
          email,
          phone,
          preferred_intake,
          interested_countries,
          interested_services: selectedTags,
          has_agreed_to_terms
        }
      );
      notification.success({
        message: response.data.message
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "Something went wrong"
      });
    }
  };

  const handleChange = (tag, checked) => {
    let nextSelectedTags;
    if (tag_choice === "single") {
      checked && (nextSelectedTags = [tag]);
    } else if (tag_choice === "multiple") {
      nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter(t => t !== tag);
    }
    setSelectedTags(nextSelectedTags);
  };

  const { getFieldDecorator } = props.form;

  return (
    <Row className="register-text">
      <Col xs={24} md={12} lg={12}>
        <img
          alt="registration-header"
          src={require(`../images/${header_image_path}.svg`)}
        />
      </Col>
      <Col xs={24} md={12} lg={12} className="register-form">
        <p>Get me free</p>
        {tags.map(tag => (
          <CheckableTag
            key={tag}
            checked={selectedTags.indexOf(tag) > -1}
            onChange={checked => handleChange(tag, checked)}
            className="registration-form-tag"
          >
            {tag}
          </CheckableTag>
        ))}

        <Form
          onSubmit={handleSubmit}
          style={{ textAlign: "initial", marginTop: "2rem" }}
        >
          <div style={{ padding: "2rem 2rem 0rem 2rem" }}>
            <Form.Item label="Name">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Please input your name!",
                    whitespace: true
                  }
                ]
              })(<Input className="register-input" />)}
            </Form.Item>

            <Form.Item label="E-mail">
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "The input is not valid E-mail!"
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!"
                  }
                ]
              })(<Input className="register-input" />)}
            </Form.Item>

            <Form.Item label="Phone Number">
              {getFieldDecorator("phone", {
                rules: [
                  { required: true, message: "Please input your phone number!" }
                ]
              })(
                <Input className="register-input" style={{ width: "100%" }} />
              )}
            </Form.Item>
            <Row type="flex" justify="center" gutter={[20, 20]}>
              <Col xs={24} md={12}>
                <Form.Item label="Interested country" hasFeedback>
                  {getFieldDecorator("interested_countries", {
                    rules: [
                      { required: true, message: "please select a country" }
                    ]
                  })(
                    <Select
                      placeholder="Please select a country"
                      className="register-select"
                      mode="multiple"
                    >
                      {countries.map(country => (
                        <Option key={country} value={country}>
                          {country}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Prefered Intake" hasFeedback>
                  {getFieldDecorator("preferred_intake", {
                    rules: [
                      { required: true, message: "please select an Intake " }
                    ]
                  })(
                    <Select
                      placeholder="Please select an Intake"
                      className="register-select"
                    >
                      {intakes.map(intake => (
                        <Option key={intake} value={intake}>
                          {intake}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              {getFieldDecorator("has_agreed_to_terms", {
                valuePropName: "checked",
                rules: [
                  {
                    required: true,
                    message: "Please accept the terms and conditions"
                  }
                ]
              })(
                <Checkbox className="register-checkbox">
                  I accept all the <a href={tnc}>terms and conditions</a>
                </Checkbox>
              )}
            </Form.Item>
          </div>
          <Button type="primary" htmlType="submit" loading={isLoading} block>
            SUBMIT
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

const WrappedRegistrationForm = Form.create({ name: "register" })(
  RegistrationForm
);

export default WrappedRegistrationForm;

/*<img
        //   width="100%"
        //   alt="registeration-page"
        //   src={require(`../images/${image_path}.svg`)}
        // />*/
