import React from "react";
import { Row, Col, Card } from "antd";
import Heading from "./Heading";
const { Meta } = Card;

const Services = ({ data, layout, isMobile }) => {
  const { values } = data;
  const { headerColor, gridConfig } = layout;

  return (
    <React.Fragment>
      {data.heading && (
        <Heading
          title={data.heading.title}
          description={data.heading.description}
          theme={headerColor}
        />
      )}
      <Row
        type="flex"
        justify="center"
        gutter={[{ xs: 24, md: 32, lg: 24 }, 20]}
      >
        {values.map(service => {
          return (
            <Col {...gridConfig} key={service.title} type="flex">
              <Card
                className="service-card"
                cover={
                  <img
                    alt={`${service.title}`}
                    src={require(`../images/${service.image_path}.svg`)}
                  />
                }
              >
                <Meta
                  className="services-text"
                  title={`${service.title}`}
                  description={`${service.description}`}
                />
              </Card>
            </Col>
          );
        })}
      </Row>
    </React.Fragment>
  );
};
export default Services;
