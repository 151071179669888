import React from "react";
import { Carousel, Card } from "antd";
import Heading from "./Heading";
const { Meta } = Card;

const setting = {
  slidesToShow: 1,
  swipeToSlide: true
};
const CountriesMobile = ({ data, layout, component_id }) => {
  const { values } = data;
  const { headerColor } = layout;

  return (
    <React.Fragment>
      {data.heading && (
        <Heading
          title={data.heading.title}
          description={data.heading.description}
          theme={headerColor}
          component_id={component_id}
        />
      )}

      <Carousel {...setting}>
        {values.map(country => {
          return (
            <Card
              className="card"
              key={`${country.name}`}
              cover={
                <img
                  className="countries-img"
                  alt={`${country.image_path}-flag`}
                  src={require(`../images/flags/${country.image_path}.png`)}
                />
              }
            >
              <Meta className="countries-text" title={country.name} />
            </Card>
          );
        })}
      </Carousel>
    </React.Fragment>
  );
};
export default CountriesMobile;
