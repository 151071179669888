import React, { useEffect, useState } from "react";
import { enquireScreen } from "enquire-js";
import {
  Registration,
  RegistrationMobile,
  Countries,
  CountriesMobile,
  Services,
  Quality,
  Highlight,
  Testimonials
} from "./index";

const components = {
  registration: Registration,
  registrationMobileComponent: RegistrationMobile,
  countries: Countries,
  countriesMobileComponent: CountriesMobile,
  services: Services,
  courses: Services,
  quality: Quality,
  highlight: Highlight,
  testimonials: Testimonials
};

const Test = ({ component, data, layout, component_id }) => {
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    enquireScreen(status => {
      setIsMobile(status);
    });
    return () => {
      //cleanup
    };
  }, [isMobile]);

  const componentName =
    isMobile && component.mobile
      ? component.mobile.name
      : component.desktop.name;

  const Component = components[componentName];

  return components[componentName] ? (
    <Component
      data={data}
      layout={layout}
      isMobile={isMobile}
      component_id={component_id}
    />
  ) : null;
};
export default Test;
