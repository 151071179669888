import React from "react";
import Heading from "./Heading";
import { Row, Col } from "antd";

const Quality = ({ data, layout }) => {
  const { values } = data;
  const { headerColor } = layout;

  return (
    <div>
      {data.heading && (
        <Heading
          title={data.heading.title}
          description={data.heading.description}
          theme={headerColor}
        />
      )}
      <Row type="flex" justify="center">
        <Col
          xs={24}
          md={12}
          lg={12}
          className="quality-image"
          type="flex"
          align="top"
        >
          <img
            alt={`${values.image_path}`}
            style={{ width: "75%" }}
            src={require(`../images/${values.image_path}.svg`)}
          />
        </Col>
        <Col xs={24} md={12} lg={12} style={{ padding: "3rem 0rem" }}>
          <div className="section-para text-color-off-white">
            <div
              className="quality-points"
              dangerouslySetInnerHTML={{ __html: values.description }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Quality;
