import React, { useEffect, useState } from "react";
import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor";
import { Test, Registration } from "../components";
import { Row, Col } from "antd";
import data from "../data.json";
import { enquireScreen } from "enquire-js";
import Header from "../components/Header";
import logo from "../images/logo.png";
import "../less/antMotionStyle.less";

configureAnchors({ scrollDuration: 1000 });

export const HeaderDataSource = {
  wrapper: { className: "header0 home-page-wrapper" },
  page: { className: "home-page" },
  logo: {
    className: "header0-logo",
    children: logo,
    url:
      process.env.NODE_ENV === "production"
        ? "https://abroad.entrancezone.com"
        : "http://localhost:3000"
  },
  Menu: {
    className: "header0-menu",
    children: [
      {
        name: "item0",
        a: {
          children: "Countries",
          href: "#countries"
        }
      },
      {
        name: "item1",
        a: {
          children: "Why EntranceZone",
          href: "#services"
        }
      },
      {
        name: "item2",
        a: { children: "Courses", href: "#courses" }
      },
      {
        name: "item3",
        a: {
          children: "Expert Guidance",
          href: "#qualities"
        }
      },
      {
        name: "item4",
        a: {
          children: "Highlights",
          href: "#highlights"
        }
      },
      {
        name: "item5",
        a: { children: "Testimonials", href: "#testimonials" }
      }
    ]
  },
  mobileMenu: { className: "header0-mobile-menu" }
};

const HomePageComponent = () => {
  const [isMobile, setIsMobile] = useState();
  useEffect(() => {
    enquireScreen(status => setIsMobile(status));
  }, []);

  return (
    <React.Fragment>
      <Header
        id="header"
        key="header"
        dataSource={HeaderDataSource}
        isMobile={isMobile}
      />
      <Registration />
      {data.map(item => {
        return (
          <ScrollableAnchor key={item.id} id={item.id}>
            <Row
              type="flex"
              justify="center"
              className={item.layout.backgroundColor}
            >
              <Col
                className={
                  item.components.desktop.name === "testimonials"
                    ? "testimonial-section-start"
                    : "section-start-end"
                }
                {...item.layout.sectionGridConfig}
                type="flex"
                align="middle"
              >
                <Test
                  component={item.components}
                  data={item.data}
                  layout={item.layout}
                  component_id={item.id}
                />
              </Col>
            </Row>
          </ScrollableAnchor>
        );
      })}
    </React.Fragment>
  );
};
export default HomePageComponent;
