import React from "react";
const footerData = {
  footer: {
    values: [
      {
        name: "Colleges",
        data: [
          {
            name: "Alpine Institute of Technology Ujjain [AIT UJJAIN]",
            url:
              "https://www.entrancezone.com/college/alpine-institute-of-technology-ujjain"
          },
          {
            name:
              "International School of Information Management Mysore [ISIM MYSOR]",
            url:
              "https://www.entrancezone.com/college/international-school-of-information-management-mysore"
          },
          {
            name:
              "Institute of Hotel Management and Catering Udaipur [IHMC UDAIPUR]",
            url:
              "https://www.entrancezone.com/college/institute-of-hotel-management-and-catering-udaipur"
          },
          {
            name:
              "Rajesh Pandey College of Law Ambedkar Nagar [RPCL AMBEDKAR NAGAR]",
            url:
              "https://www.entrancezone.com/college/rajesh-pandey-college-of-law-ambedkar-nagar"
          },
          {
            name: "Cachar College Silchar [CC SILCHAR]",
            url: "https://www.entrancezone.com/college/cachar-college-silchar"
          }
        ]
      },
      {
        name: "Universities",
        data: [
          {
            name: "Chaudhary Bansi Lal University Bhiwani [CBLU BHIWANI]",
            url:
              "https://www.entrancezone.com/university/chaudhary-bansi-lal-university-bhiwani"
          },
          {
            name: "NIMS University Jaipur [NIMS JAIPUR]",
            url:
              "https://www.entrancezone.com/university/nims-university-jaipur"
          },
          {
            name: "University of Rajasthan Jaipur [RU JAIPUR]",
            url:
              "https://www.entrancezone.com/university/university-of-rajasthan-jaipur"
          },
          {
            name: "JK Lakshmipat University Jaipur [JKLU JAIPUR]",
            url:
              "https://www.entrancezone.com/university/jk-lakshmipat-university-jaipur"
          },
          {
            name: "Homoeopathy University Jaipur [HU JAIPUR]",
            url:
              "https://www.entrancezone.com/university/homoeopathy-university-jaipur"
          }
        ]
      },
      {
        name: "Exams",
        data: [
          {
            name: "MZUEEE",
            url: "https://www.entrancezone.com/exam/mzueee"
          },
          {
            name: "PATE",
            url: "https://www.entrancezone.com/exam/pate"
          },
          {
            name: "AP LAWCET",
            url: "https://www.entrancezone.com/exam/ap-lawcet"
          },
          {
            name: "JET",
            url: "https://www.entrancezone.com/exam/jet"
          },
          {
            name: "CA Final",
            url: "https://www.entrancezone.com/exam/ca-final"
          }
        ]
      },
      {
        name: "Quick Links",
        data: [
          {
            name: "About Us",
            url: "https://www.entrancezone.com/about-us"
          },
          {
            name: "Terms Of Use",
            url: "https://www.entrancezone.com/engineering/privacy-policy/"
          },
          {
            name: "Rank Predictor",
            url: "https://www.entrancezone.com/rank-predictor"
          },
          {
            name: "Apply For Colleges",
            url: "https://apply.entrancezone.com"
          }
        ]
      }
    ]
  },
  social_media_links: [
    {
      name: "Facebook",
      icon_name: "facebook-app-symbol",
      url: "https://facebook.com/entrancezone"
    },
    {
      name: "Youtube",
      icon_name: "youtube",
      url: "https://youtube.com/entrancezone"
    },
    {
      name: "LinkedIn",
      icon_name: "linkedin-logo",
      url: "https://linkedin.com/company/entrancezone.com/"
    }
  ],
  copyright: {
    name: "2019 | All Right Reserved"
  },
  layout: {
    mobile: {},
    desktop: {},
    headerColor: "",
    backgroundColor: ""
  }
};
const Footer = () => {
  return (
    <React.Fragment>
      {/* {footerData.footer ? (
        <div className="footer-border">
          {isFooterExpanded ? (
            <button
              className="button view-btn view-btn-2"
              onClick={viewCompleteFooter}>
              <span>

                <span className="icon-baseline-expand_more-24px footer__view-btn" />
              </span>
              View Less
            </button>
          ) : (
            <button className="button view-btn" onClick={viewCompleteFooter}>
              <span>

                <span className="icon-baseline-expand_moore-24px footer__view-btn" />
              </span>
              View More
            </button>
          )}
        </div>
      ) : null}

      {isFooterExpanded ? (
        <div className="footer-2">
          <div className="footer-2__content">
            {subFooter.length > 0
              ? subFooter.map((item, ind) => {
                  let itemData = get(item, 'data', []) || [];
                  return (
                    <React.Fragment key={`${ind}footer-2`}>
                      <div className="footer-2__row--name">
                        <h4 className="heading-4 footer-2__text">
                          {item.name || ''}:
                        </h4>
                      </div>
                      <div className="footer-2__row--links">
                        {itemData.length > 0
                          ? itemData.map((subItem, index) => {
                              let { link, linkAs } = linkUrl(subItem.url);
                              return (
                                <Link
                                  href={link}
                                  as={linkAs}
                                  key={`${index}links`}
                                  prefetch={false}>
                                  <a className="footer-2__row--para">
                                    {subItem.name || ''}
                                  </a>
                                </Link>
                              );
                            })
                          : null}
                      </div>
                    </React.Fragment>
                  );
                })
              : null}
          </div>
        </div>
      ) : null} */}

      <footer className="footer">
        <div className="footer__content">
          <div className="footer__content-1">
            {footerData.footer.values.map((item, index) => {
              let itemData = item.data;
              return (
                <div className="column-1" key={`${index}content-1`}>
                  <h4 className="heading-4 column-1__text">{item.name}</h4>
                  <div className="column-1__border">
                    <div className="column-1__border--1" />
                  </div>
                  <div className="column-1__content">
                    {itemData.map((subItem, index) => {
                      return (
                        <div className="row" key={`${index}subItem`}>
                          <div className="row__image">
                            <span className="icon-baseline-arrow_right-24px row__image--icon"></span>
                          </div>
                          <a
                            href={subItem.url}
                            className="row__para"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {subItem.name || ""}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            {footerData.social_media_links ? (
              <div className="footer__bottom">
                <div className="footer__image">
                  {footerData.social_media_links.map((social, index) => {
                    return (
                      <a
                        rel="noopener noreferrer"
                        href={social.url}
                        key={social.name}
                        target="_blank"
                      >
                        <img
                          src={require(`../images/social_icons/${social.icon_name}.svg`)}
                          alt=""
                          className="footer__image--icon"
                        />
                      </a>
                    );
                  })}
                </div>
                <div className="footer__border" />
                <p className="row__para">
                  {footerData.copyright.name.split(",").join(", ")}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </footer>

      <footer className="mobile-footer">
        <div className="mobile-footer__content">
          <div className="mobile-footer__row">
            <h5 className="heading-5 mobile-footer__row--text">
              KEEP IN TOUCH
            </h5>
            <div className="mobile-footer__image">
              {footerData.social_media_links.map((social, index) => {
                return (
                  <a href={social.url} key={`${index}socialmobile`}>
                    <img
                      src={require(`../images/social_icons/${social.icon_name}.svg`)}
                      alt=""
                      className="footer__image--icon"
                    />
                  </a>
                );
              })}
            </div>
          </div>
          <div className="mobile-footer__row">
            <h5 className="heading-5 mobile-footer__row--text">USEFUL LINKS</h5>
            <div className="mobile-footer__row--link">
              {footerData.footer.values.map(itemData => {
                if (itemData.name === "Quick Links") {
                  return itemData.data.map((item, ind) => {
                    let bdr = "";
                    if (ind !== 4) {
                      bdr = "|";
                    }
                    return (
                      <a
                        rel="noopener noreferrer"
                        className="mobile-footer__row--para"
                        href={item.url}
                        key={item.url}
                      >{`${item.name} ${bdr} `}</a>
                    );
                  });
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};
export default Footer;

// import React from 'react';
// const FooterLayout = () => {
//   return <div>Footer</div>;
// };
